<template>
  <div>
    <div id="ml-top-banner" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ml-top-banner">
      <div class="col-lg-4 col-md-4 col-sm-12 hidden-sm hidden-sx">
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 mer-log-txt-col">
        <h1>Sell online with Cochchi.lk</h1> 
        <div class="ml-top-banner-inner-text parallax">
          Join thousands of Sri Lankan businesses who trust us to sell their products online!
          Selling through an online store is an easy way to increase your market and get more sales from customers who value convenience.<br/><br/> If you have an idea for starting an online business or need help getting online to take your products to a broader marketplace within Sri Lanka, then Cochchi.lk is the place for you.<br/><br/> 
          <!-- Starting a business or buying things online in Sri Lanka today is easier than ever as the Internet dominates aspects of production, social activity, and entertainment. When it comes to online shopping, Sri Lanka has seen a rapid development over the last few years. With a large percentage of shoppers and sellers venturing on to the digital platform, more and more people are finding themselves attracted to the idea of being in charge of their lives. So why shouldn’t you?<br/><br/>
          Take control today, create your online marketplace with <b>Cochchi.lk</b>. All you need is a good idea, and time to get started. Register today!  -->
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 mer-log-form-col">
        <form @submit.prevent="checkForm" id="merchantSignForm">
          <div class="login-form-container" id="lgnFormMerch">
            <div id="lgnFormMerchImg">
             <img
                :src="this.$store.state.colourLogo"
                alt="mdb logo"
                class="mdb-logo"
                style="width: 38%"
              />
            </div>
            <div id="lgnFormMerchInputs">
              <div style="text-align: center;" class="form-group">
                <div class="customToggel">
                  <ul>
                    <li
                      @click="changeType('E-Mail')"
                    >
                      <label
                        :class="switchVal === 'E-Mail'?'activeLbl':''"
                      >E-Mail</label>
                    </li>
                    <li
                      @click="changeType('Mobile')"
                    >
                      <label
                        :class="switchVal === 'Mobile'?'activeLbl':''"
                      > Mobile </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <VuePhoneNumberInput
                  v-if="this.switchVal === 'Mobile'"
                  valid-color="green"
                  error-color="red"
                  v-model="mobile"
                  @update="validateMobile"
                  :only-countries="['LK']"
                  default-country-code="LK"
                  :translations="{ phoneNumberLabel: '' }"
                  no-example
                  style="height: 38px; font-size: 18px; line-height: 1.3333333; border-radius: 6px;cursor:auto;"
                  id="mobileInput"
                />
              <!-- <VuePhoneNumberInput
                ref="mobilecom"
                valid-color="green"
                error-color="red"
                @input="validatePhone(basicInfo.mobile)"
                @update="validateMobile"
                v-model="basicInfo.mobile"
                :only-countries="['LK']"
                :default-country-code="default_country_mobile"
                :translations="{ phoneNumberLabel: '' }"
                no-example
                style="height:30px; cursor:auto;"
                id="mobileInput"
              /> -->
              </div>
              <div class="form-group">
                <input
                  type="email"
                  required
                  class="form-control input-lg-landing"
                  id="email"
                  placeholder="Registered E-Mail"
                  v-model="form.username"
                  v-if="this.switchVal === 'E-Mail'"
                  v-on:keyup.enter="onEnter()"
                />
              </div>
              <div class="form-group input-password">
                <input
                  type="password"
                  required
                  class="form-control input-lg-landing"
                  id="pwd"
                  placeholder="Password"
                  v-model="form.password"
                  v-on:keyup.enter="onEnter()"
                  ref="inputLgnPwdMer"
                />
                <span class="toggleIcon" @click="togglePwd()" ref="toggleIcon">
                  <i class="fa fa-eye"></i>
                </span>
              </div>
              <button
                id="btnlogin"
                type="submit"
                value="submit"
                :disabled="disableBtn"
                class="btn btn-lg merchant-custom-btn"
              >Login</button>
              <a style="color: white" type="button" @click="viewForgetPass">
                Forgot Password ?
              </a>
              <div class="new-ecomm">
                  New to cochchi.lk Platform? <a @click="pushToRegister()">Sign up as a seller</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
      <div id="MerchantBenefitsDIv" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h1>Merchant Benefits:</h1>
      <img src="https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1631256604/cochchi/portal_user/1631256603168.png" alt="">
      </div>
      <div id="grow-your-business" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 grow-your-business">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 feature-block">
        <div class="inner">
          <img src="../../assets/image/icon/Merchant-Icon 1.png" alt="">
          <h3 style="color: #71027E">Lower Set Up & Running Costs</h3>
          <div>
            Eliminates many of the overhead costs associated as there is no need to purchase a building, hire staff or rent office space.
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 feature-block">
        <div class="inner">
          <img src="../../assets/image/icon/Merchant-Icon 2.png" alt="">
          <h3 style="color: #71027E">Operate from Anywhere</h3>
          <div>
            If you own a laptop or a computer, you will have the flexibility to work from anywhere you have access to the Internet. It’s that simple!  
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 feature-block">
        <div class="inner">
          <img src="../../assets/image/icon/Merchant-Icon 3.png" alt="">
          <h3 style="color: #71027E">No Time Restrictions</h3>
          <div>
            The Internet is available around the clock, so you can create a work schedule that is convenient for you. Which means, you can work full-time or part-time.
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 feature-block">
        <div class="inner">
          <img src="../../assets/image/icon/Merchant-Icon 4.png" alt="">
          <h3 style="color: #71027E">More Measurable</h3>
          <div>
            Having instant access to sales figures makes it easier to measure and track results to plan on how you can better grow traffic, leads and sales etc. 
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 feature-block">
        <div class="inner">
          <img src="../../assets/image/icon/Merchant-Icon 5.png" alt="">
          <h3 style="color: #71027E">Less Time Intensive</h3>
          <div>
            Once your products are online, the whole process for customers ordering and making payments will all be activated through our system, leaving you time to focus on other areas.  
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 feature-block">
        <div class="inner">
          <img src="../../assets/image/icon/Merchant-Icon 6.png" alt="">
          <h3 style="color: #71027E">Higher Margins & Better Cashflow </h3>
          <div>
            Less overhead costs and running costs etc. means that your margins are higher. And, as the full payment goes into your account directly your cashflows will be better.   
          </div>
        </div>
      </div>
    </div>
    <div id="become-site-seller" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 become-site-seller">
      <div class="become-site-seller-top parallax">
        <button @click="pushToRegister()" class="btn btn-primary btn-lg">REGISTER <br/> TODAY!</button>
      </div>
      <!-- <div class="become-site-seller-bottom">
        <h1>Become a Elite Seller</h1>
        <div class="become-ss-inner-text">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br/> 
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
// Firebase Auth
// import firebase from "firebase";

import mixinAuth from "@/mixins/merchant/APIAuth";
import mixinProfile from "@/mixins/merchant/APIProfile";
import VuePhoneNumberInput from "vue-phone-number-input";

import MerchantForgotPassword from "@/components/common/modals/forgotPassword.vue";
export default {
  name: "MerchantLogin",
  mixins: [mixinAuth, mixinProfile],
  components: {
    MerchantForgotPassword,
    VuePhoneNumberInput
  },
  data() {
    return {
      disableBtn: false,
      form: {
        username: "",
        password: ""
      },
      currentstage: 1,
      activetab: 1,
      hideModal: 1,
      showforgotpass: false,
      resettype: "merchant",
      switchVal: "E-Mail",
      myOptions: {
        layout: {
          color: 'black',
          backgroundColor: 'white',
          selectedColor: 'white',
          selectedBackgroundColor: '#114B86',
          borderColor: 'white',
          fontFamily: 'inherit',
          fontWeight: 'normal',
          fontWeightSelected: 'bold',
          squareCorners: false,
          noBorder: false
        },
        size: {
          fontSize: 18,
          height: 4,
          padding: 0.7,
          width: 22
        },
        items: {
          delay: .4,
          preSelected: 'E-Mail',
          disabled: false,
          labels: [
            {name: 'E-Mail', color: 'white', backgroundColor: '#114B86'}, 
            {name: 'Mobile', color: 'white', backgroundColor: '#114B86'}
          ]
        }
      },
      mobile:"",
    };
  },
  created() {
    this.loggedPush();
  },
  methods: {
    viewForgetPass() {
      // this.$router.push("/reset-password-merchant-portal").catch(err => {});
      this.$router.push({ name: "Reset Password Merchant Portal" });
    },
    loggedPush() {
      if (this.$store.state.merchant_accessToken !== null) {
        this.$router.push("/merchant-order-group");
      }
    },
    validateMobile: function(payload) {
      // if (this.mobile.length === 13 && payload.formattedNumber.length === 12 && this.mobile === payload.formattedNumber) {
      //   console.log("true")
      // }
      // this.countryCodeMoblie = payload.countryCode;
      // this.dial_code = "+" + payload.countryCallingCode;
      // this.mobile = payload.formattedNumber;
      this.form.username = payload.formattedNumber;
    },
    onEnter() {
      document.getElementById("btnlogin").click();
    },
    closeModal() {
      $("#MerchantLoginModal").modal("hide");
      this.hideModal = 0;
    },
    pushToRegister() {
      this.$router.push({ name: "Merchant registerV1" });
    },
    handleContinue: async function(type) {
      let response = await this.getDashboardStage();
      if (response !== null) {
        switch (type) {
          case 1:
            if (response.complated_status === 5) {
              this.$router.push({ name: "Merchant Dashbord" });
            } else {
              this.$router.push({ name: "Merchant Profile" });
            }
            break;
          case 2:
            if (response.complated_status === 5) {
              this.$router.push({ name: "Merchant Dashbord" });
            } else {
              this.$router.push({ name: "Merchant Profile" });
            }
            break;
          default:
        }
      } else {
        this.$router.push({ name: "Merchant Profile" });
      }
      location.reload();
    },
    checkForm: async function(e) {
      this.disableBtn = true;
      e.preventDefault();

      try {
        let response = await this.merchantLogin(this.form);
        if (response.status === 200) {
          if (response.data.merchant_type === 0) {
            localStorage.setItem("merchant_token", response.data.token);
            localStorage.setItem("merchant_type", "merchant");
            localStorage.setItem("login_merchant", response.data._id);
            localStorage.setItem("merchant_data", JSON.stringify(response.data));
            this.$store.dispatch("doLoginMerchant");

            // this.$router.push("/merchant-order-group");
            if (response.data.kyc_verify === '11111' || response.data.kyc_verify === '1101') {
              if (response.data.have_product === false) {
                this.$router.push("/merchant-guidelines").catch(()=>{});
              } else {
              }
            } else {
                await this.checkRequiredStage()
            }

            if (response.data.have_product) {
              // this.$router.push("/merchant-guidelines");
              await this.checkRequiredStage()
            }
            if (response.data._id !== "") {
              this.$swal.fire({
                position: "center",
                type: "success",
                title: "Logged In!",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        }
        this.disableBtn = false;
      } catch (error) {
        this.disableBtn = false;
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    checkRequiredStage: async function () {
      let response = await this.getDashboardStage();

      if (response !== null) {
        this.currentstage = response.complated_status;
        if (this.currentstage !== 6) {
          this.$router.push("/merchant-profile").catch(()=>{});
        } else {
          this.$router.push("/merchant-dashbord").catch(()=>{});
        }
      } else {
          this.$router.push("/merchant-dashbord").catch(()=>{});
      }
      
    },
    changeType: function(val) {
      this.switchVal = val;
      this.mobile = "";
      this.form.username = "";
      this.form.password = "";
    },
    // function firebase - login with firebase popup
    // loginWithFirebasePopup: function(authProvider) {
    //   let self = this;
    //   if (authProvider == 1) {
    //     var provider = new firebase.auth.GoogleAuthProvider();
    //   } else if (authProvider == 2) {
    //     var provider = new firebase.auth.FacebookAuthProvider();
    //   }
    //   firebase
    //     .auth()
    //     .signInWithPopup(provider)
    //     .then(function(result) {
    //       firebase
    //         .auth()
    //         .currentUser.getIdToken(true)
    //         .then(function(idToken) {
    //           self.loginWithFirebase(authProvider, idToken);
    //         })
    //         .catch(function(error) {
    //           alert("error.");
    //         });
    //     })
    //     .catch(function(error) {
    //       var errorCode = error.code;
    //       var errorMessage = error.message;
    //       var email = error.email;
    //       var credential = error.credential;
    //     });
    // },
    // // login with farebase call mixin function
    // loginWithFirebase: async function(authProvider, idToken) {
    //   try {
    //     let loginobj = {
    //       login_type: 2,
    //       login_method: authProvider,
    //       token: idToken
    //     };
    //     let response = await this.firebaseLogin(loginobj);
    //     if (response.status === 200) {
    //       localStorage.setItem("merchant_token", response.data.token);
    //       localStorage.setItem("merchant_type", "merchant");
    //       localStorage.setItem("login_merchant", response.data._id);
    //       localStorage.setItem("merchant_data", JSON.stringify(response.data));
    //       this.$store.dispatch("doLoginMerchant");
    //       // this.$router.push("/merchant-dashbord");
    //       this.$router.push("/merchant-dashbord");
    //     }
    //     if (response.data._id !== "") {
    //       this.$swal.fire({
    //         position: "center",
    //         type: "success",
    //         title: "Logged In!",
    //         showConfirmButton: false,
    //         timer: 1500
    //       });
    //     }
    //   } catch (error) {
    //     this.$swal.fire({
    //       position: "center",
    //       type: "error",
    //       title: error.data.message,
    //       showConfirmButton: false,
    //       timer: 1500
    //     });
    //   }
    // },
    togglePwd: function() {
      if (this.$refs.inputLgnPwdMer.type === "password") {
        this.$refs.inputLgnPwdMer.type = "text";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputLgnPwdMer.type = "password";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye"></i>`;
      }
    }
  }
};
</script>
<style scoped>

.merchant-container {
  width: auto !important;
}
/* Top banner area styles */
#ml-top-banner{
  padding: 87px 3% 87px 3%;
}

#lgnFormMerchImg {
  background-color: white;
  padding-top: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 2px;
  margin-bottom: 20px;
}

#lgnFormMerchInputs {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
}
.ml-top-banner{
  /* height: 835px; */
  height: calc(100vw*1063/1899);
  background-image: url('https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1632220515/cochchi/mercahnt/1632220512934.png');
  /* background-attachment: fixed; */
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
#ml-top-banner .login-form-container{
  max-width: 464px;
}
#ml-top-banner h1{
  font-size: 50px;
  color: #ffffff;
  margin-top: 75px;
  margin-bottom: 25px;
}

.mer-log-txt-col {
  padding-left: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mer-log-form-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-lg-landing {
  height: 38px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

#ml-top-banner .ml-top-banner-inner-text{
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
}
#ml-top-banner .login-form-container{
  /* padding: 40px 40px 20px 40px; */
  border-radius: 10px;
  background-color: rgba(37, 130, 203, 0.7);
  border-radius: 20px;
  font-size: 14px;
  /* float: right; */
}
#ml-top-banner .login-form-container input{
  border: none;
  /* margin-bottom: 35px; */
}
#ml-top-banner .login-form-container button{
  width: 100%;
  border-radius: 5px;
  background: #114B86;
  color: #ffffff;
  border: none;
}
#ml-top-banner .login-form-container button:hover{
  opacity: 0.7;
}
#ml-top-banner .login-form-container img{
  display: block;
  margin: 0 auto 20px auto;
}
#ml-top-banner .login-form-container a{
  color: #3f5591;
}
#ml-top-banner .new-ecomm{
  text-align: center;
  margin-top: 25px;
  color: white;
}
#ml-top-banner .new-ecomm a{
  color: white;
}
#ml-top-banner .new-ecomm a:hover{
  color: #000000;
}


/* Grow your business area styles */
#grow-your-business{
  background-color: white;
  /* background-color: rgb(192, 222, 254); */
  padding-bottom: 50px;
}
#MerchantBenefitsDIv {
  background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%);
  width: 100% !important;
}

#MerchantBenefitsDIv h1{
  color: white;
  font-size: 40px;
  font-weight: bold;
  clear: both;
  text-align: center;
  /* display: inline-block; */
}

#MerchantBenefitsDIv img{
  position: absolute;
  top: -84px;
  right: 5%;
}

/* #grow-your-business h1{
  color: white;
  font-size: 40px;
  font-weight: bold;
  clear: both;
  text-align: center;
} */
#grow-your-business .feature-block{
  padding-top: 50px;
  text-align: center;
  color: #2e2e2e;
}
#grow-your-business .feature-block h3{
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}
#grow-your-business .feature-block .inner{
  max-width: 314px;
  margin: 0 auto;
  font-size: 16px;
}
#grow-your-business .feature-block .inner img{
  width: 75px;
  height: 75px;
}


/* Become site seller area styles */
#become-site-seller{
  text-align: center;
  color: #2e2e2e;
  padding: 0px;
}
#become-site-seller .become-site-seller-top{
  height: calc(100vw*1064/1901);
  width: auto;
  background: url('https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1630063121/cochchi/mercahnt/1630063119636.webp') no-repeat;
  /* background-attachment: fixed; */
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
}
#become-site-seller h1{
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 25px;
}
#become-site-seller .become-ss-inner-text{
  font-size: 20px;
  color: #9a9a9a;
  margin-bottom: 70px;
}
#become-site-seller button{
  border: none;
  border-radius: 40px;
  /* background-color: rgba(228,109,42,1); */
  background-color: #fbd402;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  font-size: 26px;
  width: 250px;
}

#become-site-seller button:hover{
  /* background-color: rgba(20,84,161,1); */
  background-color: #fbbd02;
}

#merchantSignForm .input-password input[type="password"] {
  position: relative;
}
#merchantSignForm .input-password span {
  position: relative;
  display: block;
  float: right;
  top: -34px;
  right: 7px;
  cursor: pointer;
}
#merchantSignForm .input-password .fa {
  font-size: 15px;
}



/* Media queries */

@media screen and (max-width: 1440px) {
  #ml-top-banner{
    height: 780px;
    padding: 30px 3% 30px 3%;
  }
}

@media screen and (max-width: 1280px) {
  #ml-top-banner{
    height: 638px;
  }
}

@media screen and (max-width: 992px) {
  #ml-top-banner{
    height: 1000px;
    padding: 30px 3% 30px 3%;
  }

  #MerchantBenefitsDIv img {
    position: absolute;
    top: -31px;
    right: 5%;
    height: 150%;
  }
  #ml-top-banner .login-form-container{
    float: none;
    margin: 30px auto 0 auto;
  }
  #ml-top-banner h1{
    margin-top: 40px;
  }

  .mer-log-txt-col {
    display: block;
    height:auto;
  }
  .mer-log-form-col {
    display: block;
    height:auto;
  }
}

@media screen and (max-width: 991px) {
  .ml-top-banner{
    background-position: right;
  }
}
@media screen and (max-width: 768px) {
  .ml-top-banner{
    background-position: right;
  }

  #MerchantBenefitsDIv img {
    position: absolute;
    top: -11px;
    right: 0%;
    height: 120%;
  }
}

@media screen and (max-width: 745px) {
  /* #ml-top-banner{
    height: 1100px;
  } */
}

@media screen and (max-width: 700px) {
  #MerchantBenefitsDIv img {
    display: none;
  }

  #ml-top-banner h1{
    margin-top: 27px;
    font-size: 40px;
  }
}

@media screen and (max-width: 590px) {
  /* #ml-top-banner{
    height: 1200px;
  } */

  .mer-log-txt-col {
  padding-top: 0%;
}

.mer-log-form-col {
  padding-top: 0%;
}

  .ml-top-banner{
    background-position: right;
  }
}

@media screen and (max-width: 475px) {
  #grow-your-business .feature-block{
    width: 100%;
  }
   /* #ml-top-banner{
    height: 1275px;
  } */

}

@media screen and (max-width: 440px) {
  #ml-top-banner h1{
    margin-top: 0px;
  }
  #ml-top-banner .login-form-container{
    /* padding: 30px 25px 20px 25px; */
  }
  #ml-top-banner .new-ecomm{
    margin-top: 25px;
  }
  #ml-top-banner .ml-top-banner-inner-text{
    font-size: 14px;
  }
  #become-site-seller button{
    width: auto;
    margin: 0 auto;
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
  }
}

@media screen and (max-width: 375px) {
   /* #ml-top-banner{
    height: 1200px;
  } */
  #ml-top-banner h1{
    font-size: 34px;
  }
  #ml-top-banner .login-form-container img{
    /* margin: 0 auto 20px auto; */
  }
}

@media screen and (max-width: 330px) {
   #ml-top-banner{
    height: 1400px;
  }


}



@media screen and (min-width: 475px) {
  #grow-your-business .feature-block h3{
    min-height: 95px;
  }
}

@media screen and (min-width: 575px) {
  #grow-your-business .feature-block h3{
    min-height: 80px;
    font-size: 17px;
  }
}

@media screen and (min-width: 768px) {
  #grow-your-business .feature-block h3{
    min-height: 70px;
    font-size: 20px;
  }
}
@media screen and (min-width: 1275px) {
  #grow-your-business .feature-block h3{
    min-height: 50px;
     font-size: 24px;
  }

}

</style>
